import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// import Vue from 'vue'
import AudioVisual from 'vue-audio-visual'
import VueTour from 'v3-tour'
import axios from 'axios'
import VueAxios from 'vue-axios'
import {plugin, defaultConfig} from '@formkit/vue'
// import '@formkit/themes/genesis'

// Vue.use(AudioVisual)

require('v3-tour/dist/vue-tour.css')

import '../public/expat.css';

createApp(App)
    .use(store)
    .use(router)
    .use(AudioVisual)
    .use(VueTour)
    .use(VueAxios, axios)
    .use(plugin, defaultConfig({
            theme: 'genesis' // will load from CDN and inject into document head
        })
    )
    .mount('#app')
