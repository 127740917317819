<template>
  <div>
    <div class="home">
      <img alt="Main illustration" src="../../public/img/page_garde.png"/>
      <!--p>L’expérience patient c’est « L’ensemble des interactions et des situations vécues par une personne ou son
        entourage au cours de son parcours de santé. Ces interactions sont façonnées à la fois par l’organisation de ce
        parcours mais aussi par l’histoire de vie de la personne concernée. »</p-->

      <div v-if="message" class="token_error">
        <h1>Impossible de démarrer le jeu</h1>
        <p v-if="err === 'bad_token'">Pour pouvoir jouer, vous devez utiliser le lien (URL) fourni par votre
          établissement
          ou qui vous a été donné lors de votre inscription.</p>
        <p>{{ message }}</p>
      </div>

      <div v-if="token">

        <form v-if="token && !url" @submit.prevent="startGame" class="intro_overtext">
          <p class="clientName">{{ clientName }}</p>
          <p class="urlLabel" v-if="urlLabel">{{ urlLabel }}</p>
          <span>Si vous avez déjà commencé une partie, indiquez votre code ici :</span>
          <br>
          <input type="text" v-model="code" autofocus>
          <span v-if="err" class="code_err">Ce code est incorrect</span>
          <br>
          <span>Si vous commencez une nouvelle partie, cliquez simplement sur Démarrer</span>
          <br>
          <!--      <input type="submit" value="Démarrer">-->
        </form>

        <div v-if="token && url" class="intro_overtext">
          <span>Votre code est le</span><br>
          <span class="show_code">{{ code }}</span><br>
          <span>Pensez à bien le noter au cas où vous auriez besoin d'interrompre votre partie.</span>
        </div>
      </div>

      <hr/>
      <!--    <div class="bottom_buttons">-->
      <!--      <button class="big" @click="$router.push('/about/partRA01')">Suivant</button>-->
      <!--    </div>-->
    </div>
    <div class="bottom_buttons" v-if="token">
      <button v-if="!url" @click.prevent="startGame" class="big">Démarrer</button>
      <button v-if="url" @click.prevent="next" class="big">Suivant</button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data() {
    return {
      err: '',
      message: '',
      code: '',
      url: '',
      token: '',
      clientName: '',
      urlLabel: '',
    }
  },
  async created() {
    let self = this;
    const token = self.$store.state.token;
    console.log('Checking token: ' + token);
    try {
      const {data} = await self.$http.post(
          process.env.VUE_APP_BACKEND,
          {token: token},
          {
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'X-Requested-Type': 'json',
              'X-Remote-Call': 'CheckToken'
            }
          }
      );
      if (data.status === 'OK') {
        // self.startGame();
        self.token = data.token ?? '';
        self.clientName = data.clientName ?? '';
        self.urlLabel = data.label ?? '';
      } else {
        // if (data.status === 'ERR') {
        self.message = data.message ?? 'Erreur de connexion au serveur';
        self.err = data.err;
      }
    } catch (e) {
        self.message = 'Erreur de connexion au serveur';
        self.err = '?';
    }
  },
  methods: {
    async next() {
      await this.$router.push(this.url);
    },
    async startGame() {
      let self = this;
      console.log('Starting game with ' + process.env.VUE_APP_BACKEND);
      try {
        self.err = '';

        // let code = prompt('Votre code');
        if (this.code) {
          const {data} = await this.$http.post(
              process.env.VUE_APP_BACKEND,
              {code: this.code},
              {
                headers: {
                  'X-Requested-With': 'XMLHttpRequest',
                  'X-Requested-Type': 'json',
                  'X-Remote-Call': 'CheckCode'
                }
              }
          );
          console.log('OK', data);
          if (data.status === 'OK') {
            // this.code = data.code;
            // alert('Votre nouveau code est ' + data.code);
            localStorage.setItem('expat.code', data.code);
            this.$store.commit('SET_CODE', data.code);

            this.$store.dispatch('loadProgression').then(() => {
              const url = this.$store.state.current;
              if (url) {
                console.log('Starting at ' + url);
                this.$router.push(url);
              } else {
                console.log('Starting with default location');
              }
            });
          } else {
            this.err = data.message || true;
          }
        } else {
          const {data} = await this.$http.post(
              process.env.VUE_APP_BACKEND,
              {},
              {
                headers: {
                  'X-Requested-With': 'XMLHttpRequest',
                  'X-Requested-Type': 'json',
                  'X-Remote-Call': 'GetCode'
                }
              }
          );
          console.log('OK', data);
          if ((data.status === 'OK') && (data.code)) {
            this.code = data.code;

            this.url = '/about/partRA01';
            // alert('Votre nouveau code est ' + this.code);
            localStorage.setItem('expat.code', this.code);
            this.$store.commit('SET_CODE', this.code);
            this.$store.dispatch('saveProgression', {current: this.url});
            this.$store.dispatch('sendProgression');
          } else {
            alert('Impossible d\'obtenir un nouveau code...');
          }
        }

      } catch (err) {
        console.log('ERR', err);
        alert('Une erreur s\'est produite lors de la connexion au serveur...');
      } finally {
        console.log('DONE');
      }
    }
  }
}
</script>


<style scoped>
.home {
  width: 100%;
  max-width: 1000px;
  margin: auto;
  text-align: center;
}

.home IMG {
  max-width: 100%;
}

.code_err {
  color: #cb260b;
}

.intro_overtext {
  /*position: relative;*/
  /*top: -95px;*/
  background-color: #e8e6bd;
  padding: 30px;
  text-align: center;
}

.intro_overtext INPUT {
  border: 1px solid #FF8242;
  padding: 10px;
  border-radius: 8px;
}

.intro_overtext * {
  margin: 15px;
}

.show_code {
  font-size: 120%;
  border: 1px solid #FF8242;
  color: #FF8242;
  border-radius: 8px;
  background-color: white;
  padding: 10px;
  line-height: 3;
}

.token_error {
  text-align: center;

  font-size: 120%;
  border: 1px solid #FF8242;
  border-radius: 8px;
  background-color: #f3bf8c;
  padding: 5%;
}

.token_error * {
  color: #cb260b;
  text-align: center;
}

.clientName {
  color: #FF8242;
  text-align: center;
  margin: 0;
  margin-bottom: 10px;
  font-size: 160%;
}

.urlLabel {
  color: #FF8242;
  text-align: center;
  margin: 0;
  margin-bottom: 10px;
  font-size: 120%;
}

</style>